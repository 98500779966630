import { useEffect } from 'react';

import { ClientTypeUtils } from '../../types/user';
import { useMyInstance, useParticipantFlag } from '../Player';
import { useRightPanelUIAction } from '../RightPanelContext';
import { useIsStreamSessionAlive } from '../Session';
import {
  useMyClientId,
  useMyClientType,
} from '../Venue/VenuePlaygroundProvider';
import { useRTCService } from '../WebRTC';
import { useSelectOnStageMembers } from './Context/hooks';
import { useStageControlAPI } from './Context/Provider';
import { StreamView } from './StreamView';
import { StageMode } from './types';

function getShapeclassNameByNumOfStageMembers(num: number): string {
  const classNames = ['box-content'];
  if (num === 1) {
    classNames.push(
      'w-48 h-48 lg:w-64 lg:h-64 2xl:w-72 2xl:h-72 rounded-5.5xl'
    );
  } else if (num <= 4) {
    classNames.push(
      'w-20 h-20 lg:w-25 lg:h-25 2xl:w-35 2xl:h-35 m-1 rounded-2.5xl'
    );
  } else {
    classNames.push('w-20 h-20 2xl:w-25 2xl:h-25 m-1 rounded-2.5xl');
  }
  return classNames.join(' ');
}

function StageMembers(): JSX.Element | null {
  const stageRTCService = useRTCService('stage');
  const myClientId = useMyClientId();
  const myClientType = useMyClientType();
  const stageMembers = useSelectOnStageMembers(StageMode.BOS, {
    myClientId,
  });
  const shapeclassName = getShapeclassNameByNumOfStageMembers(
    stageMembers.length
  );

  const stageControl = useStageControlAPI();

  const handleRemoveAll = async () => {
    await Promise.all(
      stageMembers.map((m) => {
        return stageControl.leave(m.id);
      })
    );
  };
  const rows = Math.round(stageMembers.length / 2);
  const zIndex = stageMembers.length > 0 ? 'z-30' : 'z-0';
  return stageMembers.length > 0 ? (
    <div
      className={`absolute w-48 lg:w-64 2xl:w-75 ${
        ClientTypeUtils.isHost(myClientType)
          ? 'right-50 lg:right-65 2xl:right-72'
          : 'right-5 lg:right-7.5 2xl:right-10'
      } top-20 bottom-50 2xl:bottom-60 perspective-700 flex flex-col justify-center ${zIndex}`}
    >
      {stageMembers.length > 1 && ClientTypeUtils.isHost(myClientType) && (
        <div className='flex items-center justify-center'>
          <button
            type='button'
            className='btn-delete text-sms py-1 px-4 mb-2'
            onClick={handleRemoveAll}
          >
            Remove All
          </button>
        </div>
      )}
      <div
        className={`flex flex-row items-start min-h-48 lg:min-h-64 2xl:min-h-72 ${
          stageMembers.length === 1 ? 'justify-end' : 'justify-center'
        } flex-wrap -rotate-y-1`}
      >
        {[...Array(rows)].map((_, i) => {
          return (
            <div key={i} className='flex flex-row'>
              <StreamView
                member={stageMembers[i * 2]}
                className={`${shapeclassName} border-4`}
                rtcService={stageRTCService}
              />
              {stageMembers[i * 2 + 1] ? (
                <StreamView
                  member={stageMembers[i * 2 + 1]}
                  className={`${shapeclassName} border-4`}
                  rtcService={stageRTCService}
                />
              ) : (
                <div
                  className={stageMembers.length > 1 ? shapeclassName : ''}
                ></div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
}

export function BringOnStage(): JSX.Element | null {
  const myClientId = useMyClientId();
  const myClientType = useMyClientType();
  const me = useMyInstance();
  const onStage = useParticipantFlag(me?.clientId, 'onStage');
  const isSessionAlive = useIsStreamSessionAlive();
  const handlePanelUIAction = useRightPanelUIAction();
  const stageControl = useStageControlAPI();

  useEffect(() => {
    if (!onStage || ClientTypeUtils.isHost(myClientType)) return;
    if (isSessionAlive) {
      handlePanelUIAction({ input: 'click-collapse' });
      return;
    }
    stageControl.leave(myClientId);
  }, [
    handlePanelUIAction,
    isSessionAlive,
    myClientId,
    myClientType,
    onStage,
    stageControl,
  ]);

  return <StageMembers />;
}
